import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Pillars from '../components/ui/pillars'
import Breadcrumbs from '../components/ui/breadcrumbs'





class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="Pillars" />
        <div className="container">
        <Breadcrumbs loc="pillars" />
        <h1>Pillars</h1>
        {/* <p>Tutaj by się przydały 2 zdania wyjaśniające co to są te pilary?</p> */}
        </div>
        
        <Pillars description />
       
      </Layout>
    )
  }
}

export default RootIndex

